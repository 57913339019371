html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, ol, ul, li,
input, textarea, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  font-family: inherit;
  vertical-align: baseline;
  background-color: transparent;
}

ol, ul {
  list-style: none;
}

s {
  text-decoration: none;
}

a {
  display: inline;
  text-decoration: none;
  font-size: inherit;
  color: inherit;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
  height: auto;
}

*, *::before, *::after {
  box-sizing: border-box;
}


body *::-webkit-scrollbar {
  width: 4px;
}

/* scrollbar itself */
body *::-webkit-scrollbar-thumb {
  background-color:  #C5D2E2;
  border-radius: 4px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

