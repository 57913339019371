.rc-calendar-picker .rc-calendar-input-wrap {
  height: 36px;
  padding: 0;
}

.rc-calendar-picker .rc-calendar-date-input-wrap {
  height: 100%;
}

.rc-calendar-picker .rc-calendar-input {
  height: 100%;
  padding: 0 10px;
  border-radius: 3px;
  box-sizing: border-box;
}
